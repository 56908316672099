import gql from "graphql-tag";

export const IS_LOGGED_IN = gql`
  query isUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GET_RESERVATIONS = gql`
  query reservations($date: ISO8601DateTime!, $untilDate: ISO8601DateTime) {
    children {
      id
      name
      firstName
      lastName
      earliestPossibleReservationDate
      daycareCenters {
        id
        opensAt
        closesAt
      }
      absences(date: $date, untilDate: $untilDate) {
        id
        description
        category
        date
      }
      reservations(date: $date, untilDate: $untilDate) {
        id
        startTime
        endTime
      }
      checkIns(date: $date, untilDate: $untilDate) {
        checkedInAt
        checkedOutAt
      }
      vacationDays(date: $date, untilDate: $untilDate) {
        id
        date
      }
      profilePicture {
        extraSmall {
          url
        }
      }
      daycarePlacements {
        id
        createdAt
      }
    }
    officialHolidays {
      date
      name
    }
    vacationPeriods {
      description
      startDate
      endDate
      id
      bookableBetween {
        fromDate
        untilDate
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query children {
    children {
      id
      firstName
      activeDaycareGroups {
        id
        name
        daycareCenter {
          id
          name
          daycareGroups {
            name
          }
          events{
            id
            daycareCenter{
              id
            }
            daycareGroups{
              id
            }
            title
            summary
            description
            responseRequired
            responseDeadline
            response{
              id
              attending
              guestCount
            }
            icon
            startDate
            endDate
            important
            startTime
            endTime
            guardiansAllowed
            reminderEnabled
          }
        }
      }
    }
  }
`;


export const CREATE_EVENT_RESPONSE = gql`
  mutation createEventResponse($eventId: ID!, $childResponses: [ChildEventResponse!], $guestCount: Int, $attending: Boolean!) {
    createEventResponse(
      eventId: $eventId,
      childResponses: $childResponses,
      guestCount: $guestCount,
      attending: $attending
    ){
          id
          attending
          children {
            id
            name
          }
          declinedChildren {
            id
            name
          }

      }
  }
`;



export const UPDATE_EVENT_RESPONSE = gql`
  mutation updateEventResponse($eventResponseId: ID! $childResponses: [ChildEventResponse!] $guestCount: Int $attending: Boolean!){
    updateEventResponse(
      eventResponseId: $eventResponseId
      childResponses: $childResponses
      guestCount: $guestCount
      attending: $attending
    ){
      id
      attending
      children {
        id
        name
      }
      declinedChildren {
        id
        name
      }
    }
  }
`;


export const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
        id
        daycareCenter{
          id
        }
        daycareGroups{
          name
          id
        }
        title
        summary
        description
        icon
        startDate
        response{
          id
          attending
          children{
            id
            firstName
          }
          guestCount
        }
        endDate
        startTime
        endTime
        important
        guardiansAllowed
        responseRequired
        responseDeadline
        reminderEnabled
        document {
          original {
            url
            metadata {
              filename
              size
            }
          }
        }
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query reservations($date: ISO8601DateTime!) {
    children {
      id
      name
      firstName
      lastName
      earliestPossibleReservationDate
      daycareCenters {
        id
        opensAt
        closesAt
      }
      absences(date: $date) {
        id
        description
        category
        date
      }
      reservations(date: $date) {
        id
        startTime
        endTime
      }
      nextReservation {
        id
        startTime
        endTime
      }
      checkIns(date: $date) {
        checkedInAt
        checkedOutAt
      }
      profilePicture {
        extraSmall {
          url
        }
      }
      daycarePlacements {
        id
        createdAt
      }
    }
    officialHolidays {
      date
      name
    }
    vacationPeriods {
      description
      startDate
      endDate
      id
      bookableBetween {
        fromDate
        untilDate
      }
    }
  }
`;


export const GET_MESSAGE_THREAD = gql`
  query messageThread($id: ID!) {
    messageThread(id: $id) {
      id
      type
      subject
      fromTouhula
      announcement
      replyable
      locked
      unreadCount
      latestMessageReadByOtherParty
      pinned
      archived
      children {
        id
        name
        firstName
        lastName
        activeDaycareGroups {
          id
          name
          daycareCenter {
            id
            name
          }
        }
        profilePicture {
          extraSmall {
            url
          }
        }
      }
      receivers {
        id
        name
        messagerType
      }
      sender {
        id
        name
        messagerType
      }
      messages {
        id
        body
        sentAt
        deletedAt
        author {
          id
          firstName
          lastName
          authorType
        }
        channel {
          id
          type
        }
        document {
          original {
            url
            metadata {
              filename
              size
            }
          }
        }
      }
    }
  }
`;

export const TOGGLE_PINNED = gql`
  mutation updateMessageThread($id: ID!, $pin: Boolean) {
    updateMessageThread(id: $id, pin: $pin) {
      id
      pinned
    }
  }
`;

export const TOGGLE_ARCHIVED = gql`
  mutation updateMessageThread($id: ID!, $archive: Boolean) {
    updateMessageThread(id: $id, archive: $archive) {
      id
      archived
    }
  }
`;

export const CURRENT_GUARDIAN = gql`
  query currentGuardian {
    currentGuardian {
      id
      email
      firstName
      lastName
      authorType
      onboardingCompleted
      helpscoutSignature
    }
  }
`;

export const GET_LIST_OF_CHILDREN = gql`
  query children {
    children {
      id
      firstName
    }
  }
`;

export const GET_CHILDREN = gql`
  query children {
    children {
      id
      name
      firstName
      lastName
      messagerType
      allergies {
        id
        name
        notes
      }
      specialCares {
        id
        name
        notes
      }
      trustedPeople {
        id
        firstName
        lastName
        phoneNumber
      }
      profilePicture {
        extraSmall {
          url
        }
      }
      activePlacements
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
    }
  }
`;

export const GET_CHILDREN_FIRST_NAMES = gql`
  query children {
    children {
      id
      firstName
    }
  }
`;
/*
export const GET_PLACEMENT_CONTACTS = gql`

  query child(id: "fffff66e-1862-4411-aaaa-e8ecba74ee5f") {
    activeDaycareGroups {
      daycareCenter {
        daycareContacts {
          name
          phoneNumber
        }
      }
    }
  }
`
*/

export const GET_CHILD = gql`
  query child($id: ID!) {
    child(id: $id) {
      id
      name
      firstName
      lastName
      legacyAllergies
      legacyMedicalConditions
      allergies {
        id
        name
        notes
      }
      specialCares {
        id
        name
        notes
      }
      trustedPeople {
        id
        firstName
        lastName
        phoneNumber
      }
      careNotes
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
      marketingArtAllowed
      specialistContactAllowed
      lutheranEventsAllowed
      guardians {
        id
        name
        phoneNumber
        workPhoneNumber
        city
        postalCode
        street
      }
      profilePicture {
        extraSmall {
          url
        }
      }
      daycarePlacements {
        startDate
        endDate
      }
    }
  }
`;

export const GET_GUARDIAN_SETTINGS = gql`
  query currentGuardian {
    currentGuardian {
      id
      settings {
        id
        reservationPeriodNotification
        newMessageNotification
        marketingMessageNotification
        newPhotoNotification
        childMessageThreadsPrivate
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateGuardianSettings(
    $newMessageNotification: Boolean
    $newPhotoNotification: Boolean
    $reservationPeriodNotification: Boolean
    $marketingMessageNotification: Boolean
    $childMessageThreadsPrivate: Boolean
  ) {
    updateGuardianSettings(
      newMessageNotification: $newMessageNotification
      newPhotoNotification: $newPhotoNotification
      reservationPeriodNotification: $reservationPeriodNotification
      marketingMessageNotification: $marketingMessageNotification
      childMessageThreadsPrivate: $childMessageThreadsPrivate
    ) {
      id
      reservationPeriodNotification
      newMessageNotification
      marketingMessageNotification
      newPhotoNotification
      childMessageThreadsPrivate
    }
  }
`;

export const CREATE_ABSENCE = gql`
  mutation createAbsence($childId: ID!, $dates: [ISO8601Date!]!, $category: AbsenceCategory!, $description: String) {
    createAbsence(childId: $childId, dates: $dates, category: $category, description: $description) {
      id
    }
  }
`;

export const GET_UNREAD_COUNT = gql`
  query unreadMessageThreadsCount {
    unreadMessageThreadsCount
  }
`;


export const MARK_ALL_MESSAGES_AS_READ = gql`
mutation markAsSeenAllMessaging{
    markAsSeenAllMessaging
}
`;

export const GET_UNREAD_ANNOUNCEMENTS_COUNT = gql`
  query unreadAnnouncementsCount {
    unreadAnnouncementsCount
  }
`;

export const GET_UNSEEN_PHOTOS_COUNT = gql`
  query currentGuardian {
    currentGuardian {
      id
      unseenPhotosCount
      __typename
    }
  }
`;

export const GET_ONBOARDING_STATUS = gql`
  query currentGuardian {
    currentGuardian {
      id
      onboardingCompleted
      onboardingCompletedAt
    }
  }
`;

export const UPDATE_ONBOARDING_STATUS = gql`
  mutation updateGuardian($onboardingCompleted: Boolean!) {
    updateGuardian(onboardingCompleted: $onboardingCompleted) {
      id
      onboardingCompleted
    }
  }
`;

export const GET_HELPSCOUT_SESSION_DATA = gql`
  query currentGuardian {
    currentGuardian {
      id
      email
      firstName
      lastName
      helpscoutSignature

      children {
        firstName
        lastName
        activeDaycareGroups {
          name
          daycareCenter {
            name
          }
        }
      }
    }
  }
`;

export const GET_ASSOCIATED_DAYCARES = gql`
  query currentGuardian {
    currentGuardian {
      id
      children {
        firstName
        lastName
        activeDaycareGroups {
          daycareCenter {
            id
            name
            streetAddress
            daycareContacts {
              name
              phoneNumber
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const GET_DAYCARE_CENTER_CONTACTS = gql`
  query daycareCenter($id: ID!) {
    daycareCenter(id: $id) {
      id
      name
      streetAddress
      daycareContacts {
        name
        phoneNumber
        createdAt
      }
    }
  }
`;

export const CREATE_NEW_CONTACT = gql`
  mutation createDaycareContact($daycareCenterId: ID!, $name: String!, $phoneNumber: String!) {
    createDaycareContact(daycareCenterId: $daycareCenterId, name: $name, phoneNumber: $phoneNumber) {
      id
      name
    }
  }
`;
export const UPDATE_CONTACT = gql`
  mutation updateDaycareContact($daycareContactId: ID!, $name: String!, $phoneNumber: String!) {
    updateDaycareContact(daycareContactId: $daycareContactId, name: $name, phoneNumber: $phoneNumber) {
      id
      name
    }
  }
`;
export const DELETE_CONTACT = gql`
  mutation deleteDaycareContact($daycareContactId: ID!) {
    deleteDaycareContact(daycareContactId: $daycareContactId) {
      id
    }
  }
`;

export const CREATE_MEDIA_ARCHIVE = gql`
  mutation createMediaArchive($mediaIds: [ID!]!) {
    createMediaArchive(mediaIds: $mediaIds) {
      id
    }
  }
`;

export const GET_MEDIA_ARCHIVE = gql`
  query mediaArchive($id: ID!) {
    mediaArchive(id: $id) {

        id
        mediaIds
        downloadUrl
        status
      
    }
  }
`;
