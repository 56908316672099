import React from "react";
import MenuIcon from "mdi-react/MenuIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import { withRouter, Link } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import styles from "./NavigationBar.module.scss";

const NavigationBar = props => {
  return (
    <ErrorBoundary>
      <div className={styles.navigationBar}>
        {props.backUrl && props.backUrl.length > 0 ? (
          <Link className={styles.left} to={props.backUrl}>
            <ChevronLeftIcon className={styles.icon} />{" "}
          </Link>
        ) : (
          <button className={styles.left} onClick={props.toggleSideNav}>
            <MenuIcon className={styles.icon} />
          </button>
        )}
        <div className={styles.middle}>
          <div className={styles.title}>{props.title || "Tougo"}</div>
        </div>
        <button className={styles.right}> {props.right} </button>
      </div>
    </ErrorBoundary>
    
  );
};

export default withRouter(NavigationBar);
