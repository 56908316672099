import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./TabBarButton.module.scss";

export const TabBarButton = props => (
  <NavLink className={styles.tabBarButton} to={props.to} activeClassName={styles.active} {...props}>
    <div className={styles.icon}>{props.icon}</div>
    <div className={styles.label}>{props.label}</div>
    {props.badge && <div className={styles.badge}>{props.badge > 99 ? "99" : props.badge}</div>}
  </NavLink>
);
