import { tougoCookies } from "../shared";

export const signOut = client => {
  if (window.TouGoAndroid && window.TouGoAndroid.onLogout) {
    window.TouGoAndroid.onLogout();
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.logout &&
    window.webkit.messageHandlers.logout.postMessage
  ) {
    window.webkit.messageHandlers.logout.postMessage({});
  }

  const allCookies = tougoCookies.getAll();
  for (const key of Object.keys(allCookies)) {
    tougoCookies.remove(key);
  }

  if (window.Beacon) {
    window.Beacon("logout", { endActiveChat: true });
  }

  client.resetStore().then(() => {
    client.cache.writeData({
      data: {
        isLoggedIn: false,
      },
    });
  });
};
