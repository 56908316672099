import React, { useEffect, useState } from "react";
import { Mutation } from "react-apollo";
import { MARK_ALL_MESSAGES_AS_READ } from "../../../../queries";
import styles from "./MarkAllAsReadButton.module.scss";
import CheckAllIcon from "mdi-react/CheckAllIcon";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import { useTranslation } from "react-i18next";

const MarkAllAsReadButton = ({ history, setMessagesMarkedAsRead }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const handleEscape = e => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };
    const handleClick = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    window.addEventListener("click", handleClick);

    return function cleanup() {
      window.removeEventListener("keydown", handleEscape);
      window.removeEventListener("click", handleClick);
    };
  });

  const markAsReadHandler = markAsRead => {
    setOpen(!open);
    setSubmitting(true);
    markAsRead()
      .then(data => {
        setSubmitting(false);
        setMessagesMarkedAsRead(true);
      })
      .catch(error => {
        setSubmitting(false);
      });
  };

  return (
    <Mutation
      mutation={MARK_ALL_MESSAGES_AS_READ}
      refetchQueries={["unreadMessageThreadsCount", "unreadAnnouncementsCount"]}
    >
      {(markAsRead, { data }) => (
        <>
          <div className={styles.root}>
            {submitting ? (
              <Loader />
            ) : (
              <Button circle onClick={() => setOpen(!open)}>
                <div className="flex align-center">
                  <CheckAllIcon />
                </div>
              </Button>
            )}
            {open && (
              <div className={styles.options}>
                <button
                  className={styles.pinButton}
                  onClick={() => markAsReadHandler(markAsRead)}
                >
                  <span>{t("Messages.mark_all_as_read")}</span>
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </Mutation>
  );
};

export default MarkAllAsReadButton;
