import React, { Component } from "react";

import styles from "./TabBar.module.scss";

class TabBar extends Component {
  renderChildrenWithContainer() {
    const { children } = this.props;
    return React.Children.map(children, child => {
      return <div className={styles.item}>{child}</div>;
    });
  }

  render() {
    return (
      <div className={styles.tabBar}>
        <div className={styles.inner}>{this.renderChildrenWithContainer()}</div>
      </div>
    );
  }
}

export default TabBar;
